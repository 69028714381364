@use './_variables.sass' as *

.object 
    width: 340px
    height: 340px
    border-radius: 10px
    border: none
    position: relative
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2)
    @media ( max-width: 768px )
        min-width: 100%
        min-height: 255px
        border-radius: 0px
        box-shadow: none
        margin-bottom: 20px
    .object-image
        width: 100%
        height: 100%
        object-fit: cover
        object-position: center
        border-radius: 10px
        z-index: -2
        &::after
            content: ""
            position: absolute
            top: 0
            left: 0
            width: 100%
            height: 100%
            border-radius: 10px
            background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.5) 100%)
            cursor: pointer
        img
            width: 100%
            height: 100%
            object-fit: cover
            object-position: center
            border-radius: 10px

    .object-description
        position: absolute
        z-index: 2  /* above the image */
        bottom: 20px
        left: 20px
        color: white
        font-family: "Montserrat", sans-serif
        font-weight: 500
        font-size: 18px