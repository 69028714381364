
.carousel-banner 
    width: 100%
    max-height: 415px
    position: relative
    padding: 0
    margin: 0
    border-radius: 25px
    text-align: center
    .pages
        position: relative
        bottom: 45px 
        color: white
        font-size: 18px
        @media (max-width: 768px)
            display: none
    .carousel-image
        width: 100%
        max-height: 415px
        object-fit: cover
        object-position: center
        border-radius: 25px
        @media (max-width: 768px) 
            width: 100%
            height: 255px
            border-radius: 10px
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2)
        img
            width: 100%
            height: 100%
            object-fit: cover
            object-position: center
            border-radius: 10px

.carousel-grid
    flex-direction: column
    justify-content: center
    align-items: center
    align-content: center
    position: relative
    .icon
        font-size: 80px
        height: 80px
        color: white
        cursor: pointer
    .icon-left 
        position: absolute
        top: 50%
        left: 43px
        color: white
        transform: translate(-50%, -50%)
        @media (max-width: 768px) 
            height: 20px
            width: 12px
            left: 12px

    .icon-right 
        position: absolute
        top: 50%
        right: -33px
        color: white
        transform: translate(-50%, -50%)
        @media (max-width: 768px) 
            height: 20px
            width: 12px
            right: 0px