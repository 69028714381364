@use './variables.sass' as *

.navbar
    display: flex 
    align-content: center
    align-items: center
    justify-content: space-between
    width: 100%
    img 
        height: 120px
        width: auto
        margin-right: 20px
        @media (max-width: 768px) 
            height: 80px


    .list 
        color: $primary-color
        display: flex
        align-items: center
        align-content: center
        justify-content: space-between
        list-style: none
        gap: 60px
        @media (max-width: 768px) 
            gap: 20px
        a
            font-size: 24px
            font-family: "Montserrat", sans-serif
            font-weight: 500
            color: $primary-color
            text-decoration: none
            @media (max-width: 768px) 
                font-size: 18px



.underline
    text-decoration: underline !important
    text-underline-offset: 3px

