@use './_variables.sass' as *
.dropdowns-container
    display: flex
    align-content: center
    justify-content: space-evenly
    width: 100%
    gap: 80px
    margin-top: 26px
    height: 90vh
    @media (max-width: 768px)
        flex-direction: column
        justify-content: flex-start
        gap: 20px
        height: 100%
        margin-top: 20px
        margin-bottom: 20px
    .dropdown
        width: 50%
        position: relative
        display: table
        @media (max-width: 768px)
            width: 100%
        .dropdown-title
            display: flex
            flex-direction: row
            align-items: center
            align-content: center
            justify-content: space-between
            background-color: $primary-color
            border-radius: 10px
            padding: 10px
            max-height: 52px
            @media (max-width: 768px)
                max-height: 30px
                padding: 5px 10px
            .dropdown-title-text
                font-size: 18px
                font-weight: 500
                color: white
                -webkit-user-select: none /* Safari */        
                -moz-user-select: none /* Firefox */
                -ms-user-select: none/* IE10+/Edge */
                user-select: none /* Standard */
                @media (max-width: 768px)
                    font-size: 13px
            .dropdown-title-icon
                color: white
                font-size: 26px
                cursor: pointer
        .dropdown-item
            position: relative
            top: -5px
            z-index: -1
            display: none
            visibility: hidden
            opacity: 0
            background: $secondary-color
            height: 0%
            width: 100%
            overflow: hidden
            transition: display 0.2s ease-in-out 1s
            font-size: 18px
            color: $primary-color
            letter-spacing: 1.5px
            font-weight: 400
            border-radius: 0 0 10px 10px
            padding: 10px 0 10px 0px
            @media (max-width: 768px)
                    font-size: 12px
            .dropdown-list
                padding: 20px
                margin: 0
                list-style: none
                li 
                    margin-top: 5px
            .dropdown-description
                padding: 20px
                margin: 0 
                text-align: start

.item-display
    display: block !important
    visibility: visible !important
    opacity: 1 !important
    width: 100% !important
    height: 100% !important

.width-85
    width: 85% !important
    @media (max-width: 768px)
        width: 100%!important

.flex-wrap
    flex-wrap: wrap !important
    align-content: flex-start !important

.fit-content
    display: block !important
    height: 25vh !important
