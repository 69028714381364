@use './components/variables.sass' as *
@use './components/navbar.sass' as *
@use './components/thumb.sass' as *
@use './components/carousel.sass' as *
@use './components/tagline.sass' as *
@use './components/ratings.sass' as *
@use './components/dropdown.sass' as *
@use './components/footer.sass' as *
@use './pages/home.sass' as *
@use './pages/error.sass' as *
@use './pages/profile.sass' as *


html,body
    font-family: $font-family
    margin: 0
    padding: 0
    overflow-x: hidden
    scroll-behavior: smooth



.App 
    width: 100%
    max-width: 1240px
    margin: 0 auto
    @media (max-width: 768px)
        padding: 0px 20px
        width: 90vw
        overflow-x: hidden !important
        height: 100%!important
        
.page-container
    display: flex
    flex-direction: column
    min-height: 100vh
