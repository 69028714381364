.footer
    width: 100% !important
    background-color: black !important
    color: white !important
    display: flex
    flex-direction: column
    text-align: center
    padding: 60px 0px 20px 0px
    flex: 1
    margin-top:200px
    @media (max-width: 768px) 
        max-height: 20vh
        height: 20% 
        margin-top: auto
        position: relative
        bottom: 0

    
    .footer-logo
        margin-bottom: 32px
        max-width: 680px
        // change the image color to white

            
