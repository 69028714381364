@use "./_variables" as *

.tagline-tag
    display: flex 
    align-items: center
    align-content: center
    gap: 10px
    .amenities
        display: flex
        align-items: center
        align-content: center
        justify-content: space-between
        gap: 10px

.tagline
    padding: 10px 20px
    background-color: $primary-color
    border-radius: 25px
    font-size: 14px
    font-weight: 500
    color: white
    max-height: 20px
    @media (max-width: 768px)
        font-size: 10px
        padding: 4px 15px