@use "../components/variables.sass" as *

.container 
    display: grid
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr))
    justify-content: space-between
    flex-wrap: wrap
    height: 100%
    background-color: $secondary-color
    margin-top: 43px
    border-radius: 25px
    column-gap: 60px
    row-gap: 50px
    padding: 50px
    justify-items: center
    @media (max-width: 768px) 
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr))
        justify-content: space-around
        flex-wrap: wrap
        height: 100%
        width: 100%
        background-color: white
        margin-top: 20px
        border-radius: 25px
        column-gap: 0
        row-gap: 20px
        padding: 0

.banner 
    width: 100%
    max-height: 223px
    position: relative
    padding: 0
    margin: 0
    border-radius: 25px
    text-align: center
    

    img
        width: 100%
        height: 100%
        object-fit: cover
        object-position: center
        filter: brightness(0.75)
        border-radius: 25px
        @media (max-width: 768px) 
            width: 100%
            min-height: 110px
            border-radius: 10px
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2)
    .text
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        width: 100%
        height: 100%
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        vertical-align: middle
        text-align: center
        h1
            display: inline-table
            vertical-align: middle
            line-height: 100%
            color: white
            font-family: "Montserrat",Fallback, sans-serif
            font-weight: 300
            font-size: 48px
            @media (max-width: 768px) 
                font-size: 24px
                text-align: start
                margin-right: 90px
                width: 220px