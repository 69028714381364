@use './_variables.sass' as *
    
.start-label
    input[type="radio"]
        display: none


.gray-star
    color: #ccc
.yellow-star
    color: $primary-color

.star
    width: 30px
    height: 30px
    @media (max-width: 768px)
        width: 12px
        height: 12px