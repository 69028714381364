@use '../components/variables.sass' as *

.ProfileHeader
    display: flex
    justify-content: space-between
    align-items: flex-end
    @media (max-width: 768px)
        flex-direction: column
        align-items: flex-start
    .title-container
        color: $primary-color
        font-weight: 400
        @media (max-width: 768px)
            width: 100%
        .profile-title
            margin: 0
            font-size: 36px
            font-weight: 500
            font-family: Monstserrat
            @media (max-width: 768px)
                font-size: 18px
        .subtitle
            font-size: 18px
            margin: 5px 0 23px 0
            font-weight: 200
            @media (max-width: 768px)
                font-size: 14px
    .people-section
        display: flex
        align-items: flex-end
        flex-direction: column
        gap: 25px
        @media (max-width: 768px)
            gap: 0
            width: 100%
            flex-direction: row-reverse
            align-items: center
            justify-content: space-between
        .profile-avatar
            display: flex
            flex-direction: row
            align-items: center
            gap: 30px
            @media (max-width: 768px)
                gap: 10px
            .avatar-name
                font-size: 18px
                font-weight: 300
                color: $primary-color
                text-align: end
                max-width: 52px
                @media (max-width: 768px)
                    font-size: 12px
            img
                height: 64px
                width: 64px
                border-radius: 50%
                object-fit: cover
                @media (max-width: 768px)
                    width: 32px
                    height: 32px
